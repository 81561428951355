<template>
  <div class="conten">
    <!-- 背景 -->
    <img class="bj-img" src="./assets/img/1.png" alt="">
    <!-- top按钮 -->
    <div class="home-tab">
      <a-tabs size="large" v-model:activeKey="activeKey" @change="tabChange" centered>
        <a-tab-pane key="0" tab="首页"></a-tab-pane>
        <a-tab-pane key="1" tab="业务范围" force-render></a-tab-pane>
        <a-tab-pane key="2" tab="工程业绩"></a-tab-pane>
        <a-tab-pane key="3" tab="成功案例"></a-tab-pane>
        <a-tab-pane key="4" tab="关于我们"></a-tab-pane>
      </a-tabs>
    </div>
    <!-- 二维码 -->
    <div class="code-img">
      <img src="./assets/img/24.png" alt="">
      <p>关注我们</p>
    </div>
    <!-- 联系我们 -->
    <div class="phone-box">
      <p class="title">联系我们</p>
      <p>杨经理:19383081888</p>
      <p>邓经理:19383083888</p>
      <p>谢经理:19383082888</p>
      <p>售后客服:4000831020</p>
    </div>
    <!-- 回到顶部 -->
    <a-back-top ref="backTop">
      <UpCircleTwoTone :style="{ fontSize: '32px' }" />
    </a-back-top>
    <!-- 轮播 -->
    <div class="carousel">
      <a-carousel autoplay>
        <div>
          <img class="img" src="./assets/img/2.png" alt="">
        </div>
        <div>
          <img class="img" src="./assets/img/3.png" alt="">
        </div>
      </a-carousel>
    </div>
    <!-- 页面类容 -->
    <div v-if="activeKey == '0'" class="img-box">
      <img src="./assets/img/4.jpg" alt="">
      <img src="./assets/img/5.jpg" alt="">
      <img src="./assets/img/6.jpg" alt="">
      <img src="./assets/img/7.jpg" alt="">
    </div>
    <div v-else-if="activeKey == '1'" class="img-box">
      <img src="./assets/img/8.jpg" alt="">
      <img src="./assets/img/9.jpg" alt="">
      <img src="./assets/img/10.jpg" alt="">
      <img src="./assets/img/11.jpg" alt="">
      <img src="./assets/img/12.jpg" alt="">
      <img src="./assets/img/13.jpg" alt="">
      <img src="./assets/img/14.jpg" alt="">
      <img src="./assets/img/15.jpg" alt="">
      <img src="./assets/img/16.jpg" alt="">
    </div>
    <div v-else-if="activeKey == '2'" class="img-box">
      <img src="./assets/img/17.jpg" alt="">
      <img src="./assets/img/18.jpg" alt="">
      <img src="./assets/img/19.jpg" alt="">
      <img src="./assets/img/20.jpg" alt="">
    </div>
    <div v-else-if="activeKey == '3'" class="img-box">
      <img src="./assets/img/21.jpg" alt="">
      <img src="./assets/img/22.jpg" alt="">
    </div>
    <div v-else-if="activeKey == '4'" class="img-box">
      <img src="./assets/img/23.jpg" alt="">
    </div>
    <div class="footer">
      <span @click="openKeep">蜀ICP备2022016825号-1</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
<script setup>
import { ref } from "vue";
import { UpCircleTwoTone } from "@ant-design/icons-vue";
let activeKey = ref("0"),
  backTop = ref();
function tabChange(index) {
  window.scrollTo(0, 0);
  backTop.value.click();
  activeKey.value = index;
}
function openKeep() {
  window.open("https://beian.miit.gov.cn");
}
</script>
<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 100;
  span {
    cursor: pointer;
  }
}
.img-box {
  img {
    width: 100%;
  }
}
.home-tab {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 400px;
  width: 100%;
  height: 70px;
  background: #fff;
  z-index: 1000;
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
}
.conten {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 480px 300px 0 300px;
  box-sizing: border-box;
  margin-top: 70px;
  margin-bottom: 40px;
  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    height: 480px;
    width: 100%;
    padding: 0 200px;
    box-sizing: border-box;
    .img {
      width: 100%;
      height: 480px;
    }
    .ant-tabs-nav-wrap {
      justify-content: flex-end;
    }
  }
  .img-box {
    width: 100%;
    z-index: 100;
    img {
      width: 100%;
    }
  }
  .bj-img {
    position: fixed;
    top: 0;
    left: 0;
  }
}
.code-img {
  position: fixed;
  left: 50px;
  bottom: 50px;
  color: #565656;
  box-shadow: 2px 2px 10px #909090;
  padding: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  img {
    width: 100px;
    height: 100px;
  }
  p {
    margin-top: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
}
.phone-box {
  position: fixed;
  right: 50px;
  bottom: 120px;
  color: #565656;
  box-shadow: 2px 2px 10px #909090;
  padding: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  .title {
    margin-bottom: 10px;
    font-size: 18px;
  }
  p {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
}
.ant-back-top {
  right: 240px;
  bottom: 20px;
}
</style>
